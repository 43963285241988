import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {Router, RouterModule} from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise/main';
import { environment } from '@env/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.module.routing';
import { AuthInterceptor } from './utils/interceptors/auth.interceptor';
import { AuthState } from '@redux/auth/auth-state';
import { ProcessState } from '@redux/process/process-state';
import { AuthGuard } from '@utils/guards/auth.guard';
import { NoAuthGuard } from '@utils/guards/noauth.guard';
import { LayoutState } from '@redux/layout/layout-state';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppService } from './app.service';
import { ToastrModule } from 'ngx-toastr';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import localeEsCo from '@angular/common/locales/es-CO';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!

import { createErrorHandler, TraceService} from '@sentry/angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

registerLocaleData(localeEsCo);

LicenseManager.setLicenseKey('');
LicenseManager['outputMessage'] = function (data1, data2) {};

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 0,
  prefix: '$ ',
  suffix: '',
  thousands: '.'
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxsModule.forRoot([
      ProcessState,
      AuthState,
      LayoutState,
    ], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.dataToken', 'layout', 'empleados.filters_grid', 'auth.version'],
    }),
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    CKEditorModule,
    FullCalendarModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    AppService,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AppService) => () => ds.verify(),
      deps: [AppService],
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'COP' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      }),
    },
    {
    provide: TraceService,
    deps: [Router],
    useValue: undefined
},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
